@import '../Style/Variables.css';

nav {
  display: flex;
  align-items: center;
  margin: 0 20px 0 10px;
  /* margin-inline: 20px; */
  a {
    color: var(--grayedText);
  }
  .active {
    color: var(--headerContent);
  }
}

nav ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  gap: 10px;
}

nav ul li a {
  text-decoration: none;
}

.sideAccountMenuContainer {
  position: fixed;
  display: flex;
  width: 80vw;
  background-color: var(--mainColor);
  top: 0;
  right: 0;
  transform: translateX(80vw);
  height: 100%;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.2s ease-in-out;
}

.opened {
  transform: translateX(0);
}

.headerWhatsappButtonRow {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px !important;
  span {
    color: #b6b6b6;
  }
}

.headerWhatsappButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: var(--highlightGreen);
  transition: background-color 0.2s ease, color 0.2s ease;
}
.headerWhatsappButtonRow:hover {
  span {
    color: var(--highlightGreen);
  }
  .headerWhatsappButton {
    background-color: var(--highlightGreen);
    color: white;
  }
}

.sideMenuColumn {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  align-items: start;
  margin-top: 10px;
  padding: 60px 20px;
  gap: 30px;
  font-size: 18px;
}

.sideMenuCloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
}

.desktopNavRow {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  gap: 12px;
  margin-inline: 4px 6px;
  color: #b6b6b6;
}

.cartItemCounter {
  position: absolute;
  top: -12px;
  right: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondColor);
  border-radius: 50%;
  color: var(--secondContent);
  width: 16px;
  height: 16px;
  font-size: 10px;
}

.cartItemCounterSideMenu {
  position: absolute;
  top: -6px;
  left: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondColor);
  border-radius: 50%;
  color: var(--secondContent);
  width: 16px;
  height: 16px;
  font-size: 10px;
}

.vehicleFilterContainer {
  cursor: pointer;
  position: relative;
}

.vehicleFilterContainer:hover {
  /* box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
  transform: scale(1.02); */
  .vehicleFilterName {
    background-color: var(--mainColorHover);
  }
}

.vehicleFilterRow {
  display: flex;
  flex-direction: row;
  border-radius: var(--smallBorderRadius);
  overflow: hidden;
  height: 32px;
  transition: transform 0.2s, box-shadow 0.2s;
  background-color: var(--lightGray);
  /* box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); */
}

.vehicleFilterImgContainer {
  display: flex;
  padding-inline: 8px;
  object-fit: contain;
  overflow: hidden;
}

.vehicleFilterImg {
  width: 72px;
}

.vehicleFilterName {
  display: flex;
  padding: 5px;
  height: 32px;
  white-space: nowrap;
  background-color: var(--mainColor);
  color: var(--mainContent);
  transition: background-color 0.2s ease;
}

.vehicleFilterButton {
  height: 32px;
}

@media (max-width: 600px) {
  .vehicleFilterImgContainer {
    width: 48px;
    height: 32px;
    object-fit: contain;
    justify-content: end;
    padding: 0;
    border-radius: var(--smallBorderRadius);
    /* border: 1px solid lightGray; */
    background-color: var(--lightGray);
    overflow: hidden;
  }
  /* .vehicleFilterImgContainer {
    width: 36px;
    height: 36px;
    object-fit: contain;
    justify-content: end;
    padding: 0;
    border-radius: 50%;
    border: 1px solid lightGray;
    background-color: var(--lightGray);
  } */
  .vehicleFilterRow {
    background-color: transparent;
  }
  .vehicleFilterImg {
    width: 100px;
    height: auto;
    transform: translate(-4px, -3px);
  }
  .vehicleFilterName {
    display: none;
  }
  .vehicleFilterRow {
    box-shadow: none;
  }
}